import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import axios from "axios";

// Import icons
import white_device from "../../Images/flutter/white_device.png";
import logout from "../../Images/flutter/logout.png";
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterPaymentHistory = () => {
    const location = useLocation();
    const [userDashboard, setUserDashboard] = useState([]);
    const [daysRemaining, setDaysRemaining] = useState('Na/Na');
    const [expirationDate, setExpirationDate] = useState('');
    const userId = localStorage.getItem('user_id') || '';
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);

    useEffect(() => {
        // Apply or remove background class based on route
        if (location.pathname == '/flutter-payments-history') {
            document.body.classList.add('no-black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        // Fetch user dashboard data
        const fetchData = async () => {
            try {
                const response = await axios.post(`${API_BASE_URL}api/v1/dashboard`, {
                    user_id: userId,
                });
                const dashboardData = response.data.VIDEO_STREAMING_APP || [];
                setUserDashboard(dashboardData);

                if (dashboardData.length > 0) {
                    // Parse expiration date and calculate days remaining
                    const expiresOn = new Date(dashboardData[0].expires_on);
                    setExpirationDate(expiresOn.toLocaleDateString("en-US", {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }));

                    const currentDate = new Date();
                    const timeDifference = expiresOn - currentDate;
                    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                    setDaysRemaining(daysLeft > 0 ? daysLeft : 'Na/Na');
                }
                setpagesSpinnerFlutter(false)
            } catch (err) {
                console.error("Error fetching user dashboard:", err);
            }
        };

        fetchData();
    }, [userId]);


    return (
        <>
            <FlutterBackHeader />
            <section className="flutter__payment__history">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* Check if user data is available */}
                            {userDashboard.length == 0 ? (
                                <div className="subscription__page__flutter">
                                    <p>You have not made any payments yet</p>
                                </div>
                            ) : (
                                <div className="table__main__profile__device">
                                    <div className="heading__device_profile__main">
                                        <div className="icon__devices__profile">
                                            <img src={white_device} alt="Device Icon" />
                                        </div>
                                        <p>Your Current Subscription Details.</p>
                                    </div>
                                    {/* Subscription Details Table */}
                                    {/* <div className="table__flutter__device table-responsive">
                                    <table className="table table-dark table-bordered">
                                        <thead style={{ background: '#3A3A3A' }}>
                                            <tr>
                                                <th scope="col">Current Plan</th>
                                                <th scope="col">Expiration Date</th>
                                                <th scope="col">Msg</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{userDashboard[0].current_plan || 'N/A'}</td>
                                                <td>{expirationDate || 'N/A'}</td>
                                                <td>{daysRemaining}</td>
                                                <td>
                                                    <img src={logout} alt="Logout" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                                    {/* Transaction History Table */}
                                    <div className="table__flutter__device table-responsive mt-4">
                                        <h5>Transaction History</h5>
                                        <table className="table table-dark table-bordered">
                                            <thead style={{ background: '#3A3A3A' }}>
                                                <tr>
                                                    <th scope="col">Plan Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Payment Gateway</th>
                                                    <th scope="col">Payment ID</th>
                                                    <th scope="col">Payment Date</th>
                                                    <th scope="col">Currency Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userDashboard[0].transactions_list.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>{transaction.plan_name}</td>
                                                        <td>{transaction.amount}</td>
                                                        <td>{transaction.payment_gateway}</td>
                                                        <td>{transaction.payment_id}</td>
                                                        <td>{transaction.payment_date}</td>
                                                        <td>{transaction.currency_code}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FlutterPaymentHistory;
