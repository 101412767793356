import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import detail_head from '../../Images/flutter/detail_pg.png';
import arrow_right from '../../Images/flutter/arrow_right.png';
import hd from '../../Images/flutter/hd.png';
import axios from "axios";
import FlutterPlayer from "../../Components/FlutterPlayer";
import pencil_icon from "../../Images/flutter/pencilIcon.png";
import small_arrow from "../../Images/flutter/small_arrow.png";
import icon from "../../Images/flutter/icon.png";
import edit_profile from "../../Images/flutter/edit_profile.png";
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterAddProfile = () => {
    const location = useLocation();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [displayBlock, setDisplayBlock] = useState('none');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Add or remove class based on the current route
        if (location.pathname === `/flutter-add-profile`) {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);


    const handleSubmit = () => {
        if (!name || !age) {
            setResponseMessage('Please fill out all fields.');
            setDisplayBlock('block');
            return;
        }

        setLoading(true);
        setDisplayBlock('none');

        const formData = new FormData();
        formData.append('user_id', UserId);
        formData.append('name', name);
        formData.append('user_image', selectedImage);
        formData.append('age', age);

        axios.post(`${API_BASE_URL}api/v1/sub_account_create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            if (response.data.status_code == 401) {
                setResponseMessage(response.data.VIDEO_STREAMING_APP);
            } else {
                console.log(response.data.VIDEO_STREAMING_APP[0].msg);
                setResponseMessage(response.data.VIDEO_STREAMING_APP[0].msg);
                window.location.href = '/flutter-multi-profiles';
            }
            setDisplayBlock('block');
        }).catch(err => {
            setResponseMessage('An error occurred while creating the profile.');
            setDisplayBlock('block');
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            console.log(file);
        }
    };


    return (
        <>
            {/* Season Header */}
        <FlutterBackHeader />
            <section class="multi__edit_profiles_flutter">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile__edit_main"
                                onClick={handleImageClick}>
                                <div className="profile__avatar_futter"z
                                    style={{ overflow: 'hidden', height: '100px', width: '100px', borderRadius: '100%' }}>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    {selectedImage ? (
                                        <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                                    ) : (
                                        <img src={edit_profile} alt="" />
                                    )}
                                </div>
                                <div className="profile__edit_title__flutter">
                                    <p>Choose Avatar</p>
                                </div>
                            </div>
                            {/*  From Edit */}
                            <div className="edit__profile_form__main_flutter">
                                <div className="form__profile_flutter">
                                    <form action="">
                                        <div className="group__input">
                                            <label htmlFor="">Your Name</label>

                                            <input
                                                type="text"
                                                className="edit__profile_flutter_field"
                                                placeholder="Write Your Desired Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />

                                        </div>
                                        <div className="group__input">
                                            <label htmlFor="">What age-appropriate content do you watch?</label>

                                            <select name="" id="" className="edit__profile_flutter_field"
                                                value={age}
                                                onChange={(e) => setAge(e.target.value)}
                                            >
                                                <option value="" disabled>Select Age Group</option>
                                                <option value="3">up to 3 years</option>
                                                <option value="5">up to 5 years</option>
                                                <option value="12">up to 12 years</option>
                                                <option value="15">up to 15 years</option>
                                                <option value="18">up to 18 years</option>
                                                <option value="1000">everyone</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* Buttons */}
                            <div className="response_div">
                                {loading && (
                                    <div className="loader_section" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                                    </div>
                                )}
                                <p className={`response_sub_account m-0 d-block`}>{responseMessage}</p>
                            </div>
                            <div className="profile__btns_flutter">
                                <Link to="#" className="btn_profile_edit save_btn"
                                    onClick={handleSubmit}>Add</Link>
                                <Link to="/flutter-multi-profiles" className="btn_profile_edit btn_btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FlutterAddProfile;
