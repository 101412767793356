import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import ErrorBoundary from './Components/ErrorBoundary';
import HomePage from './Pages/HomePage';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import MovieDetails from './Pages/MovieDetails';
import WatchList from './Pages/WatchList';
import BioGraphy from './Pages/BioGraphy';
import CollectionListPage from './Pages/CollectionList';
// import Search from './Components/Search';
import { useState, useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
import Login from './Pages/Login';
import SubscriptionPage from './Pages/SubscriptionPage';
import Dashboard from './Pages/Dashboard';
import UserHistory from './Components/UserHistory';
import ResponsiveNav from './Components/ResponsiveNav';
// import Home from './Pages/Home';
import ViewMoreGenre from './Pages/Viewmorebygenre';
import MoviesMenuGenre from './Pages/MoviesMenuGenre';
import SignUp from './Pages/sign-up';
import Movies from './Pages/Movies';
import Lenis from 'lenis'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from 'aos';
import EditProfile from './Pages/Editprofile';
import 'aos/dist/aos.css';
import ProcessLogin from './Pages/ProcessLogin';
import PaymentPage from './Pages/PaymentPage';
import SuccessPayment from './Pages/Successpayment';
import PaymentCancelled from './Pages/paymentCancelled';
import WatchMovie from './Pages/WatchMovie';
import Childrens from './Pages/Childrens';
import Serials from './Pages/Serials';
import Showsdetail from './Pages/Showsdetail';
import Episodesshows from './Pages/Episodesshows';
import WatchEpisode from './Pages/WatchEpisode';
import Livetv from './Pages/Livetv';
import LiveTvdetail from './Pages/LiveTvdetail';
import MultiProfiles from './Pages/MultiProfiles';
import AddnewProfile from './Pages/AddnewProfile';
import SerialsViewAll from './Pages/SerialsViewAll';
import Kids from './Pages/Kids';
import Search from './Pages/Search';
import ExitChildMode from './Pages/ExitChildMode';
import AllMoviesPage from './Pages/AllMoviesPage';
import AllSerialsPage from './Pages/AllSerialsPage'
import AllLiveTvPage from './Pages/AllLiveTvPage';
import ViewAllLiveTv from './Pages/ViewAllLiveTv';
import ContactUs from './Pages/ContactUs';
import ApplicationDownload from './Pages/ApplicationDownload';
import PaymentError from './Pages/PaymentError';
import FlutterPlayer from './Components/FlutterPlayer';
import FlutterPlayerPage from './Pages/FlutterPlayerPage';
// Cinema
import CinemaPaymentPage from './Pages/CinemaPaymentPage';
import CinemaSuccessPayment from './Pages/CinemaSuccesspayment';
import CinemapaymentCancelled from './Pages/CinemapaymentCancelled';
import MoviesByFilter from './Pages/MoviesByFilter';
import ShowsByFilter from './Pages/ShowsByFilter';
import SortByMovie from './Pages/SortByMovie';
import MainScreen from './Pages/FlutterPages/MainScreen';
import FlutterMovieDetailPage from './Pages/FlutterPages/FlutterMovieDetailPage';
import FlutterShowDetailPage from './Pages/FlutterPages/FlutterShowDetailPage';
import FlutterOtherEpisodes from './Pages/FlutterPages/FlutterOtherEpisodes';
import FlutterViewedPage from './Pages/FlutterPages/FlutterViewedPage';
import FlutterFeaturedPage from './Pages/FlutterPages/FlutterWatchList';
import FlutterFeaturedAllMoviesSerials from './Pages/FlutterPages/FlutterFeaturedAllMoviesSerials';
import FlutterFreeMoviesSerials from './Pages/FlutterPages/FlutterFreeMoviesSerials';
import FlutterLiveTv from './Pages/FlutterPages/FlutterLiveTv';
import FlutterListGenreMoivesShows from './Pages/FlutterPages/FlutterListGenreMoivesShows';
import FlutterOnlineScreenPurchase from './Pages/FlutterPages/FlutterOnlineScreenPurchase';
import FlutterLogin from './Pages/FlutterPages/FlutterLogin';
import FlutterLoginOtp from './Pages/FlutterPages/FlutterLoginOtp';
import FlutterCategories from './Pages/FlutterPages/FlutterCategories';
import LiveTvPlayerFlutter from './Pages/FlutterPages/flutter_component/LiveTvPlayerFlutter';
import FlutterPlayerMovieShowTrailerPage from './Pages/FlutterPages/FlutterPlayerMovieShowTrailerPage';
import FlutterMovieShowTrailerPlayer from './Pages/FlutterPages/flutter_component/FlutterMovieShowTrailerPlayer';
import FlutterShowTrailerPlayer from './Pages/FlutterPages/flutter_component/FlutterShowTrailerPlayer';
import FlutterPlayerShowTrailerPage from './Pages/FlutterPages/FlutterPlayerShowTrailerPage';
import FlutterWatchList from './Pages/FlutterPages/FlutterWatchList';
import FlutterDashboard from './Pages/FlutterPages/FlutterDashboard';
import FlutterSubscription from './Pages/FlutterSubscription';
import FlutterWatchEpisode from './Pages/FlutterPages/FlutterWatchEpisode';
import FlutterMultiProfiles from './Pages/FlutterPages/FlutterMultiProfiles';
import FlutterMultiProfileEdit from './Pages/FlutterPages/FlutterMultiProfileEdit';
import FlutterAddProfile from './Pages/FlutterPages/FlutterAddProfile';
import FlutterChildModeOtp from './Pages/FlutterPages/FlutterChildModeOtp';
import FlutterSearchPage from './Pages/FlutterPages/FlutterSearchPage';
import FlutterBeforeLoginScreen from './Pages/FlutterPages/FlutterBeforeLoginScreen';
import FlutterProfilePage from './Pages/FlutterPages/FlutterProfilePage';
import FlutterEditProfile from './Pages/FlutterPages/FlutterEditProfile';
import FlutterProfileDevice from './Pages/FlutterPages/FlutterProfileDevice';
import FlutterPaymentHistory from './Pages/FlutterPages/FlutterPaymentHistory';
import FlutterAboutPage from './Pages/FlutterPages/FlutterAboutPage';
import FlutterContactUs from './Pages/FlutterPages/FlutterContactUs';
import FlutterHomeScreenNav from './Pages/FlutterPages/flutter_component/FlutterHomeScreenNav';
import FlutterPaymentProcess from './Pages/FlutterPages/FlutterPaymentProcess';
import FlutterSucessPayment from './Pages/FlutterPages/FlutterSucessPayment';
import FlutterPaymentCancelled from './Pages/FlutterPages/FlutterPaymentCancelled';
import FlutterPaymentError from './Pages/FlutterPages/FlutterPaymentError';
import FlutterGalleryOffline from './Pages/FlutterPages/FlutterGalleryOffline';
import FlutterNewestShowsPlayer from './Pages/FlutterPages/FlutterNewestShowsPlayer';
import FlutterNavTabs from './Pages/FlutterPages/flutter_component/FlutterNavTabs';
import FlutterMainNavTwo from './Pages/FlutterPages/flutter_component/FlutterMainNavTwo';
import FlutterEpisodeDetailPage from './Pages/FlutterPages/FlutterEpisodeDetailPage';
import FlutterBottomBar from './Pages/FlutterPages/flutter_component/FlutterBottomBar';

const App = () => {

  // useEffect(() => {
  // AOS.init();
  // }, [])

  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [isTallScreen, setIsTallScreen] = useState(window.innerWidth > 800);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsTallScreen(window.innerWidth > 800);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  // gsap.registerPlugin(useGSAP, ScrollTrigger);
  // // Smooth Scrolling
  // const lenis = new Lenis();
  // const lenisJs = () => {
  //   lenis.on("scroll", (e) => { });

  //   lenis.on("scroll", ScrollTrigger.update);

  //   gsap.ticker.add((time) => {
  //     lenis.raf(time * 300);
  //   });

  //   gsap.ticker.lagSmoothing(0);
  // };
  // lenisJs();

  const location = useLocation();
  const hideNavbarPaths = ['/login', '/subscription', '/exit-child-mode', '/verification-process', '/custom-player-view', '/flutter-movie-player', '/flutter-home-screen', '/flutter-movie-detail-screen', '/flutter-show-detail-screen', '/flutter-other-episodes', '/flutter-marked-page', '/flutter-viewed-page', '/flutter-featured-page', '/flutter-featured-all-movies-serials', '/flutter-free-movies-serials', '/flutter-livetv-all', '/flutter-genre-all-movie-serials/:id', '/flutter-purchase-online-screen', '/flutter-login', '/flutter-login-otp', '/flutter-categories', '/flutter-watch-list-page', '/flutter-dashboard', '/flutter-subscription', '/flutter-watch-episode', '/flutter-multi-profiles', '/flutter-edit-profile', '/flutter-add-profile', '/flutter-child-mode-verification', '/flutter-search-page', '/flutter-before-login-screen', '/flutter-profile-page', '/flutter-profile-edit-page', '/flutter-profile-your-device', '/flutter-payments-history', '/flutter-about-us', '/flutter-contact-us', '/flutter-payment-process', '/flutter-success-payment', '/flutter-payment-cancelled', '/flutter-payment-error', '/flutter-offline-movies', '/flutter-newest-shows-player', '/flutter-swiper-tab'];

  // const hideElement_conditions = !hideNavbarPaths.some(path => location.pathname.startsWith(path));

  // Check if the path matches exactly or starts with a dynamic path

  const hideElement_conditions = !(
    hideNavbarPaths.some(path => location.pathname.startsWith(path)) ||
    location.pathname.startsWith('/flutter-genre-all-movie-serials/') || // Handle dynamic segment for this path
    // location.pathname.startsWith('/flutter-other-episodes/')||             // Handle dynamic segment for this path
    location.pathname.startsWith('/flutter-livetv-player/') ||           // Handle dynamic segment for this path
    location.pathname.startsWith('/flutter-movie-show-trailer-page/') ||
    location.pathname.startsWith('/flutter-show-trailer-page/') ||
    location.pathname.startsWith('/flutter-movie-trailer-page/') ||
    location.pathname.startsWith('/flutter-watch-episode/') ||
    location.pathname.startsWith('/flutter-edit-profile/') || 
    location.pathname.startsWith('/flutter-episode-detail/') 
  );

  useEffect(() => {
    if (!localStorage.getItem('theme')) {
        localStorage.setItem('theme', 'Light');
    }

    const excludePaths = [
      '/flutter-home-screen', 
      '/flutter-categories',
      '/flutter-livetv-all',
      '/flutter-offline-movies',
      '/flutter-viewed-page',
      '/flutter-watch-list-page',
      '/flutter-profile-page',
      '/flutter-before-login-screen',
      '/flutter-login',  // Make sure to include flutter-login in the excludePaths
      '/flutter-profile-edit-page',
      '/flutter-profile-your-device',
      '/flutter-payments-history',
      '/flutter-other-episodes',
      '/flutter-search-page',
      '/flutter-login-otp',
      '/flutter-free-movies-serials',
      '/flutter-featured-all-movies-serials'
  ];
  
  // Add regex patterns for dynamic paths
  const dynamicPathPatterns = [
      /^\/flutter-movie-detail-screen\/\d+$/,
      /^\/flutter-show-detail-screen\/\d+$/,
      /^\/flutter-episode-detail\/\d+$/,
      /^\/flutter-genre-all-movie-serials\/\d+$/
  ];
  
  // Helper function to check if path is excluded
  const isExcludedPath = (path) => {
      // Check if path is in the static excludePaths
      if (excludePaths.includes(path)) return true;
  
      // Check if path matches any dynamic patterns
      return dynamicPathPatterns.some((pattern) => pattern.test(path));
  };
  
  // Apply background class based on theme and path
  if (isExcludedPath(location.pathname)) {
      if (location.pathname != '/flutter-login' && location.pathname != '/flutter-login-otp' && localStorage.getItem('theme') === 'Dark') {
          document.body.classList.add('dark__background');
          document.body.classList.remove('no-black-background');
      } else {
          document.body.classList.add('no-black-background');
          document.body.classList.remove('dark__background');
      }
  } else {
      document.body.classList.remove('dark__background');
      document.body.classList.remove('no-black-background');
  }
  
    // Cleanup to remove background classes on component unmount or path change
    return () => {
        document.body.classList.remove('dark__background');
        document.body.classList.remove('no-black-background');
    };
}, [location.pathname]);


const showBottomBarRoutes = [
  '/flutter-home-screen',
  'flutter-viewed-page',
  'flutter-watch-list-page', 
  'flutter-offline-movies', 
  'flutter-categories',
  '/flutter-livetv-all'
];

const showBottomBar = showBottomBarRoutes.some(route => location.pathname.includes(route));


  return (
    <div className="App">
      {/* <Router> */}
      {hideElement_conditions && <Navbar />} {/* Conditionally render Navbar */}
      <ScrollToTop />
      <div className="clearfix"> {/* Added clearfix wrapper */}
        <Routes>
    
          {/* <Route path='/' element={<Home/>}/> */}
          <Route path='/' element={<HomePage />} />
          <Route path='/movies/:id' element={<Movies />} />
          <Route path='/movies' element={<AllMoviesPage />} />
          <Route path='/childrens/:id' element={<Childrens />} />
          <Route path='/serials/:id' element={<Serials />} />
          <Route path='/serials' element={<AllSerialsPage />} />
          <Route path='/show-detail/:id' element={<Showsdetail />} />
          <Route path='/watch-list' element={<WatchList />} />
          <Route path='/collection-list' element={<CollectionListPage />} />
          <Route path='/login' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <Navigate to="/profile/dashboard" /> : <Login />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/subscription' element={<SubscriptionPage />} />
          <Route path="/verification-process" element={localStorage.getItem("phoneNumberVerification") ? <ProcessLogin /> : <Navigate to="/login" />} />
          {/* <Route path="/verification-process" element={ <ProcessLogin /> } /> */}
          <Route path='/payment-process' element={localStorage.getItem('user_id') && localStorage.getItem('plan_id') && localStorage.getItem('session_name') ? <PaymentPage /> : <Navigate to="/login" />} />
          <Route path='/success-payment' element={localStorage.getItem('user_id') && localStorage.getItem('payment_id') && localStorage.getItem('session_name') ? <SuccessPayment /> : <Navigate to="/subscription" />} />
          {/* <Route path='/success-payment' element={<SuccessPayment />} /> */}
          <Route path='/payment-cancelled' element={<PaymentCancelled />} />
          <Route path='/payment-error' element={<PaymentError />} />
          <Route path='/profile/dashboard' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path='/profile/edit' element={<EditProfile />} />
          <Route path='/user-history' element={<UserHistory />} />
          <Route path="/view-all/:id" element={<ViewMoreGenre />}></Route>
          <Route path="/view-all-shows/:id" element={<SerialsViewAll />}></Route>
          <Route path="/movies-genre/:id" element={<MoviesMenuGenre />}></Route>
          <Route path="/movie-page/:id" element={<MovieDetails />}></Route>
          <Route path='/watch-movie/:id' element={<WatchMovie />}></Route>
          <Route path='/episodes/:id' element={<Episodesshows />}></Route>
          <Route path='/watch-episode/:id' element={<WatchEpisode />}></Route>
          <Route path='/live-tv/:id' element={<Livetv />}></Route>
          <Route path='/live-tv' element={<AllLiveTvPage />}></Route>
          {/* <Route path='/view-all-live-tv' element={<ViewAllLiveTv />}></Route> */}
          <Route path='/view-all-live-tv/:id' element={<ViewAllLiveTv />}></Route>
          <Route path='/live-tv-detail/:id' element={<LiveTvdetail />}></Route>
          <Route path='/multiple-profiles' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <MultiProfiles /> : <Navigate to="/login" />}></Route>
          <Route path='/multiple-profile/add-new' element={<AddnewProfile />}></Route>
          <Route path='/kids' element={<Kids />}></Route>
          <Route path='/search/:search_record' element={<Search />}></Route>
          <Route path='/exit-child-mode' element={<ExitChildMode />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          <Route path='/app' element={<ApplicationDownload />}></Route>
          {/* Cinema Payment Route */}
          <Route path='/payment-process-cinema' element={localStorage.getItem('user_id') && localStorage.getItem('plan_id') && localStorage.getItem('session_name') ? <CinemaPaymentPage /> : <Navigate to="/login" />} />
          <Route path='/success-payment-cinema' element={localStorage.getItem('user_id') && localStorage.getItem('payment_id') && localStorage.getItem('session_name') ? <CinemaSuccessPayment /> : <Navigate to="/" />} />
          <Route path='/payment-cancelled-cinema' element={<CinemapaymentCancelled />} />

          <Route path='/movies-by-filter' element={<MoviesByFilter />} />
          <Route path='/shows-by-filter' element={<ShowsByFilter />} />
          <Route path='/sort-by-movie' element={<SortByMovie />} />

          <Route path='/sort-by-movie' element={<FlutterPlayer />} />

          {/* For Flutter */}
          {/*login  */}
          <Route path='/flutter-login' element={<FlutterLogin />} />
          <Route path='/flutter-login-otp' element={localStorage.getItem('user_id') 
            ? <Navigate to="/flutter-home-screen" replace /> 
            : <FlutterLoginOtp /> }/>

          {/*login  */}
          <Route path='/flutter-categories' element={<FlutterCategories />} />
          <Route path='/flutter-home-screen' element={<MainScreen />} />
          <Route path='/flutter-movie-detail-screen/:id' element={<FlutterMovieDetailPage />} />
          <Route path='/flutter-show-detail-screen/:id' element={<FlutterShowDetailPage />} />
          <Route path='/flutter-other-episodes' element={<FlutterOtherEpisodes />} />
          {/* <Route path='/flutter-other-episodes/:id' element={<FlutterOtherEpisodes />} /> */}

          <Route path='/flutter-viewed-page' element={<FlutterViewedPage />} />
          <Route path='/flutter-watch-list-page' element={<FlutterWatchList />} />
          <Route path='/flutter-offline-movies' element={<FlutterGalleryOffline />} />

          <Route path='/flutter-featured-all-movies-serials' element={<FlutterFeaturedAllMoviesSerials />} />
          <Route path='/flutter-free-movies-serials' element={<FlutterFreeMoviesSerials />} />
          <Route path='/flutter-livetv-all' element={<FlutterLiveTv />} />
          <Route path='/flutter-genre-all-movie-serials/:id' element={<FlutterListGenreMoivesShows />} />
          <Route path='/flutter-multi-profiles' element={<FlutterMultiProfiles />} />
          <Route path='/flutter-edit-profile/:id' element={<FlutterMultiProfileEdit />} />
          <Route path='/flutter-add-profile' element={<FlutterAddProfile />} />
          <Route path='/flutter-child-mode-verification' element={<FlutterChildModeOtp />} />
          {/* Online screening */}
          <Route path='/flutter-purchase-online-screen' element={<FlutterOnlineScreenPurchase />} />
          {/* Players Flutter */}
          <Route path='/flutter-movie-player/:id' element={<FlutterPlayerPage />} />
          <Route path='/flutter-movie-trailer-page/:id' element={<FlutterPlayerMovieShowTrailerPage />} />
          <Route path='/flutter-show-trailer-page/:id' element={<FlutterPlayerShowTrailerPage />} />
          <Route path='/flutter-livetv-player/:id' element={<LiveTvPlayerFlutter />} />
          <Route path="/flutter-dashboard" element={<FlutterDashboard />} />
          <Route path="/flutter-subscription" element={<FlutterSubscription />} />
          <Route path='/flutter-watch-episode/:id' element={<FlutterWatchEpisode />} />
          <Route path='/flutter-search-page' element={<FlutterSearchPage />} />
          {/* Before Login Screen */}
          {/* <Route path='/flutter-before-login-screen' element={<FlutterBeforeLoginScreen />} /> */}
          
          <Route path='/flutter-before-login-screen' element={localStorage.getItem('user_id') 
            ? <Navigate to="/flutter-home-screen" replace /> 
            : <FlutterBeforeLoginScreen /> }/>
          <Route path="/flutter-profile-page" element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <FlutterProfilePage /> : <Navigate to="/flutter-login" replace />} />
          <Route path='/flutter-profile-edit-page' element={<FlutterEditProfile />} />
          <Route path='/flutter-profile-your-device' element={<FlutterProfileDevice />} />
          <Route path='/flutter-payments-history' element={<FlutterPaymentHistory />} />
          <Route path='/flutter-about-us' element={<FlutterAboutPage />} />
          <Route path='/flutter-contact-us' element={<FlutterContactUs />} />
          {/* Payment Work Of Flutter */}

          <Route path='/flutter-payment-process' element={localStorage.getItem('user_id') && localStorage.getItem('plan_id') && localStorage.getItem('session_name') ? <FlutterPaymentProcess /> : <Navigate to="/flutter-login" />} />
          <Route path='/flutter-success-payment' element={localStorage.getItem('user_id') && localStorage.getItem('payment_id') && localStorage.getItem('session_name') ? <FlutterSucessPayment /> : <Navigate to="/flutter-subscription" />} />
          <Route path='/flutter-payment-cancelled' element={<FlutterPaymentCancelled />} />
          <Route path='/flutter-payment-error' element={<FlutterPaymentError />} />

          {/* Newest Shows Player */}
          <Route path='/flutter-newest-shows-player' element={<FlutterNewestShowsPlayer />} />
          {/* <Route path='/flutter-swiper-tab' element={<FlutterNavTabs />} /> */}

          <Route path='/flutter-episode-detail/:id' element={<FlutterEpisodeDetailPage />} />

        </Routes>

          {showBottomBar && <FlutterBottomBar />}

        {/* For Flutter */}
      </div>
      {/* <Search showSearch={showSearch} setShowSearch={setShowSearch} /> */}
      {hideElement_conditions && <Footer />}
      {/* </Router> */}
    </div>
  );
}

const AppWrapper = () => {
  return (
    <Router>
      {/* <ErrorBoundary> */}
      <App />
      {/* </ErrorBoundary> */}
    </Router>
  );
};

export default AppWrapper;