import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Theme_Variable } from '../../../config';
import { API_BASE_URL } from '../../../config';
import axios from "axios";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../../Images/flutter/nav/share-icon.png';

import white1 from '../../../Images/flutter/nav/white1.png';
import white2 from '../../../Images/flutter/nav/white2.png';
import white3 from '../../../Images/flutter/nav/white3.png';

import arrwhite from '../../../Images/flutter/nav/arr_white.png';
import arrblack from '../../../Images/flutter/nav/arr_black.png';

import watchListBlackFilled from '../../../Images/flutter/watchlistFilledblack.png';
import watchListWhiteFilled from '../../../Images/flutter/watchlistFilledwhite.png';

const FlutterNavdetail = ({ user_id, detail_id, type }) => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [messagealert, setMessageAlert] = useState('');
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const [watchListAdded, setWatchListAdded] = useState(false);
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') || 0;

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // // Check if item is in the watchlist
    // useEffect(() => {
    //     axios.post(`${API_BASE_URL}api/v1/my_watchlist`, { user_id })
    //         .then(response => {
    //             const watchlistData = response.data.VIDEO_STREAMING_APP;
    //             const itemInWatchlist = watchlistData.find(
    //                 item => item.post_id == detail_id && item.post_type === 'Movies'
    //             );
    //             setWatchListAdded(!!itemInWatchlist);
    //         })
    //         .catch(err => console.error("Watchlist fetching error:", err));
    // }, [user_id, detail_id]);

    // const WatchLater = () => {
    //     // setPagesSpinnerFlutter(true);
    //     axios.post(`${API_BASE_URL}api/v1/watchlist_add`, {
    //         user_id,
    //         post_id: detail_id,
    //         post_type: type
    //     })
    //         .then(response => {
    //             const { success, msg } = response.data.VIDEO_STREAMING_APP[0];
    //             setMessageAlert(msg);
    //             if (success == 1) setWatchListAdded(true);

    //             setTimeout(() => setMessageAlert(''), 2000);
    //             // setPagesSpinnerFlutter(false);
    //         })
    //         .catch(err => {
    //             // setPagesSpinnerFlutter(false);
    //             console.error("Add to watchlist error:", err);
    //         });
    // };

    // const watchlistRemove = () => {
    //     axios.post(`${API_BASE_URL}api/v1/watchlist_remove`, {
    //         user_id,
    //         post_id: detail_id,
    //         post_type: type
    //     })
    //         .then(() => setWatchListAdded(false))
    //         .catch(err => console.error("Delete watchlist error:", err));
    // };

    // Watch Apis

    const [loading, setLoading] = useState(false);
    const [watchlistloading, setwatchlistloading] = useState(false);
    const [preLoading, setpreLoading] = useState(true);
    const [movieDetail, setMovieDetail] = useState([]);

    useEffect(() => {
        setpreLoading(true);
        axios
            .post(`${API_BASE_URL}api/v1/my_watchlist`, { user_id: UserId })
            .then(response => {
                const watchlistData = response.data.VIDEO_STREAMING_APP;
                let itemInWatchlist;

                if (type == 'Movies') {
                    itemInWatchlist = watchlistData.find(item => item.post_id == detail_id && item.post_type == type);
                } else if (type == 'Shows') {
                    itemInWatchlist = watchlistData.find(item => item.episode_id == detail_id && item.post_type == type);
                } else if (type == 'Episodes') {
                    itemInWatchlist = watchlistData.find(item => item.post_id == detail_id && item.post_type == type);
                }

                console.log("Item WatchList for Movies and Shows:", itemInWatchlist);
                setWatchListAdded(itemInWatchlist);
                setpreLoading(false);
            })
            .catch(err => {
                setpreLoading(false);
                console.error("Watchlist fetching error:", err);
            });
    }, [UserId, type, detail_id]);

    //   Add watchlist api
    const WatchLater = () => {
        setwatchlistloading(true);
        axios.post(`${API_BASE_URL}api/v1/watchlist_add`, {
            user_id,
            post_id: detail_id,
            post_type: type
        })
            .then(response => {
                setwatchlistloading(false);
                setWatchListAdded(true);
            })
            .catch(err => {
                setwatchlistloading(false); // Handle loading state in case of an error
                console.log("Add to watchlist error: " + err);
            });
    };

    const watchlistRemove = () => {
        setwatchlistloading(true);
        axios.post(`${API_BASE_URL}api/v1/watchlist_remove`, {
            user_id,
            post_id: detail_id,
            post_type: type
        })
            .then(response => {
                setwatchlistloading(false);
                setWatchListAdded(false); // Update the state to reflect the removal
            })
            .catch(err => {
                setwatchlistloading(false); // Handle loading state in case of an error
                console.log("Delete watchlist error: " + err);
            });
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/movies_details`, {
            user_id: UserId,
            'movie_id': detail_id
        }).then(response => {
            setMovieDetail(response.data.VIDEO_STREAMING_APP)
        }).catch(err => {
            console.log(err);
        })
    }, []);

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };


    // const  downloadFile = (file_url) => {
    //     alert(file_url);
    //     // Show the spinner
    //     axios.get(`${API_BASE_URL}api/v1/download_file`, {
    //         'file':file_url
    //     })
    //         .then(response => {
    //             console.log(response);
    //         })
    //         .catch(err => {
    //             console.log('There was an error making the request:', err);
    //         });
    // };

    const downloadFile = async (file_url) => {
        try {
            const response = await axios.get(`${API_BASE_URL}api/v1/download_file?file=` + file_url, {
                responseType: 'blob', // This is important for handling file downloads
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'withoutvoice.mp4'); // Set the file name here
            document.body.appendChild(link);
            link.click();
            link.remove(); // Remove the link after download

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };


    return (
        <>
            {/* <section className={`Flutter_nav  ${isScrolled ? 'scrolled' : ''}`}> */}

            <section className={`Flutter_nav  ${Theme_Variable == 'Dark' ? isScrolled ? 'scrolled_dark' : '' : isScrolled ? 'scrolled' : ''}`}>

                <div className="arrow__back_main" onClick={handleBack} style={{ cursor: 'pointer' }}>
                    <p className={`${Theme_Variable == 'Dark' ? isScrolled ? 'white_back' : 'white_back' : isScrolled ? 'black__back' : 'white_back'}`}>back</p>
                    <div className="arrow_icon_back">
                        <img src={`${Theme_Variable == 'Dark' ? isScrolled ? arrwhite : arrwhite : isScrolled ? arrblack : arrwhite}`} loading="lazy" alt="" />
                    </div>
                </div>
                <div className="newFlix_links_nav_flutter">
                    <ul className="list-unstyled d-flex">
                        <li>
                            {/* <Link to="#" onClick={WatchLater}>
                                <div className="icons__nav_main watchlist_flutter">
                                    <img src={isScrolled ? watchlist : white3} alt="Watchlist" />
                                </div>
                            </Link> */}
                            {/* 
                            {
                                !watchListAdded ? (
                                    <Link to="#" onClick={watchlistRemove}>
                                        <div className="icons__nav_main watchlist_flutter">
                                            <img src={isScrolled ? watchlist : white3} alt="Watchlist" />
                                        </div>
                                    </Link>
                                ) : (
                                    <Link to="#" onClick={WatchLater}>
                                        <div className="icons__nav_main watchlist_flutter">
                                            <img src={isScrolled ? watchListBlack : watchListWhite} alt="Watchlist" />
                                        </div>
                                    </Link>
                                )
                            } */}
                            {
                                !watchListAdded ? (
                                    !watchlistloading && (
                                        <Link to="#" onClick={WatchLater}>
                                            <div className="icons__nav_main watchlist_flutter">
                                                <img src={`${Theme_Variable == 'Dark' ? isScrolled ? white3 : white3 : isScrolled ? watchlist : white3}`} loading="lazy" alt="" />
                                            </div>
                                        </Link>
                                    )
                                ) : (
                                    !watchlistloading && (
                                        <Link to="#" onClick={watchlistRemove}>
                                            <div className="icons__nav_main watchlist_flutter">
                                                <img src={`${Theme_Variable == 'Dark' ? isScrolled ? watchListWhiteFilled : watchListWhiteFilled : isScrolled ? watchListBlackFilled : watchListWhiteFilled}`} loading="lazy" alt="" />
                                            </div>
                                        </Link>
                                    )
                                )
                            }
                        </li>
                        <li>
                            {
                                movieDetail.download_enable == 'false' ? (
                                    ''
                                ) : (
                                    <Link to={`${API_BASE_URL}api/v1/download_file?file=` + movieDetail.video_url_download} >
                                        <div className="icons__nav_main download_flutter">
                                            <img src={`${Theme_Variable == 'Dark' ? isScrolled ? white2 : white2 : isScrolled ? download_black : white2}`} loading="lazy" alt="" />
                                        </div>
                                    </Link>
                                )
                            }
                        </li>
                        <li>
                            <div
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent page reload

                                    const currentURL = window.location.href; // Full URL for sharing

                                    if (navigator.share) {
                                        navigator.share({
                                            title: 'Check out this movie!',
                                            url: currentURL,
                                            text: 'Watch this awesome movie here!',
                                        })
                                            .then(() => console.log('Successfully shared'))
                                            .catch((error) => console.error('Error sharing:', error));
                                    } else {
                                        // Fallback for unsupported browsers and WebViews
                                        window.open(currentURL, '_blank');
                                    }
                                }}
                                className="icons__nav_main share_flutter"
                            >
                                <img
                                    src={`${Theme_Variable == 'Dark' ? (isScrolled ? white1 : white1) : (isScrolled ? shareIcon : white1)}`}
                                    loading="lazy"
                                    alt="Share Icon"
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            {messagealert && (
                <div className="message_watchlist active_message_watchlist">{messagealert}</div>
            )}
            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FlutterNavdetail;
