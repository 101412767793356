import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import axios from "axios";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import delete_device from "../../Images/flutter/delete.png"
import white_device from "../../Images/flutter/white_device.png";
import logout from "../../Images/flutter/logout.png";
import just_icon from "../../Images/flutter/just_icon.png";
import FlutterBackHeader from "./FlutterBackHeader";


const FlutterAboutPage = () => {

    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [messagealert, setMessageAlert] = useState('');
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const [watchListAdded, setWatchListAdded] = useState(false);
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        // Check the current route and apply/remove the class accordingly
        if (location.pathname === '/flutter-payements-history') {
            document.body.classList.add('no-black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    return (
        <>
        
        <FlutterBackHeader/>
        <section className="flutter__about__main">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="newflix__icon__flutter">
                            <img src={just_icon} alt="" />
                        </div>
                        <div className="flutter__about__content">
                            <p>Newflix is a rich archive of movies, series, documentaries, and film-theater, which can be accessed via the internet on smartphones, personal computers, and smart TVs. You can adjust the quality of Filimo based on your internet speed. To send your opinions, feedback, suggestions, or questions, please email the Filimo support team at:</p>
                        </div>
                        {/* links about us */}

                        <div className="email_about__us">
                            <p>support@newflix.com</p>
                        </div>


                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default FlutterAboutPage;