import { useState, useEffect } from "react";
import { API_BASE_URL,Theme_Variable } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
// import '../../Components/css/style.css';

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import hd from '../../Images/flutter/hd.png';
import not_found from '../../Images/flutter/not_found.jpg';
import err_2 from '../../Images/flutter/err_2.jpg';
import FlutterBackHeader from "./FlutterBackHeader";
import watchlist_dark from "../../Images/flutter/watch_list_dark.png";
import FlutterNavTabs from "./flutter_component/FlutterNavTabs";
import FlutterMainNavTwo from "./flutter_component/FlutterMainNavTwo";
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import download_dark from '../../Images/flutter/download_dark.png';
import download_light from '../../Images/flutter/download_light.png';

const FlutterWatchList = () => {

    const UserId = localStorage.getItem('user_id') || ''; // Check if user is logged in
    const location = useLocation();
    const [pagesSpinner, setPagesSpinner] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [watchlist, setWatchlist] = useState([]);

    // Function to toggle the overlay
    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        // Check the current route and apply/remove the class accordingly
            if (location.pathname == '/flutter-watch-list-page') {
                document.body.classList.toggle('no-black-background');
                document.body.classList.toggle('black-background', Theme_Variable == 'Dark');
            } else {
                document.body.classList.remove('no-black-background', 'black-background');
            }
    }, [location.pathname]);

    useEffect(() => {
        // setpreLoading(true)
        setPagesSpinner(true);
        axios.post(`${API_BASE_URL}api/v1/my_watchlist`, {
            user_id: UserId
        })
            .then(response => {
                setPagesSpinner(false);
                // setpreLoading(false);
                setWatchlist(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Watchlist fetching error:" + err);
            });
    }, []);

    return (
        <>
        <FlutterMainNavTwo/>
        <FlutterNavTabs/>

            {/* Show the first section only if user is not logged in */}
            {!UserId && (
                <section className={`fluttered_user_detailed_section`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="detailed_user_main">
                                    <div className="detailed_image_user">
                                    <img src={Theme_Variable == 'Dark' ? watchlist_dark : err_2 } alt="Not Found" />
                                    </div>
                                    <div className="detailed_title">
                                        <h2 className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>You haven't watched any movies yet!</h2>
                                        <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>To access the features and movies of NewFlix, you must first log in.</p>
                                        <Link to="/flutter-login">Login Or SignUp</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* Show the second section only if user is logged in */}
            {UserId && (
                <section className={`featured_fetch_flutter`} >
                    <div className="featured_row">

                        {
                            watchlist.length > 0 ? (
                                watchlist.map((userWatchList, index) => (
                                    <div className="featured_main" key={index}>
                                        <img src={userWatchList.post_image} alt="" />
                                        <div className="hd_icon">
                                            <img src={hd} alt="" />
                                        </div>
                                        <div className="featured_title_flutter">
                                            <p className={Theme_Variable === 'Dark' ? 'white_text_mode' : ''}>{userWatchList.post_title}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <section className={`fluttered_user_detailed_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="detailed_user_main">
                                                    <div className="detailed_image_user">
                                                        <img src={Theme_Variable == 'Dark' ? watchlist_dark : err_2 } alt="Not Found" />
                                                    </div>
                                                    <div className="detailed_title">
                                                        <h2 className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>No movies in watchlist</h2>
                                                        <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>The list of movies you have Watchlisted on Newflix will be shown here</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )
                        }
                    </div>
                </section>
            )}
              {pagesSpinner && (
                <div className="flutter_section_spinner">
                    <div className="flutter_spinned_sections"></div>
                </div>
            )}
            {/* <FlutterBottomBar/> */}
        </>
    );
};


export default FlutterWatchList;
