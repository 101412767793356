import { useState, useEffect } from "react";
import { Theme_Variable } from '../../config';
import { API_BASE_URL } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
// import '../../Components/css/style.css';

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import photoSlider from '../../Images/photo.png';
import actor_logo from '../../Images/actor.png';
import play_icon from '../../Images/flutter/play.png';
import logo_channel from '../../Images/flutter/logo_ch.png';
import free_movie from '../../Images/flutter/free.png';
import reel from '../../Images/flutter/reel1.png';
import screen from '../../Images/flutter/screen.png';
import logo_icon from '../../Images/flutter/just_icon.png';
import newflix_logo from '../../Images/newflix-logo.png';
import nav1 from '../../Images/flutter/nav1.png';
import nav2 from '../../Images/flutter/nav2.png';
import search from '../../Images/flutter/search.jpg';
import arr from '../../Images/flutter/arrow_right.png';
import arr_black from '../../Images/flutter/arr_black.png';
import search_white from '../../Images/flutter/NewFlix Images/Icons/search_white.png';
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import FlutterHomeScreenNav from "./flutter_component/FlutterHomeScreenNav";

const MainScreen = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [sliderFlutter, setSliderFlutter] = useState([]);
    const [feature, setfeatureFlutter] = useState([]);
    const [livetv, setlivetvFlutter] = useState([]);
    const [movie_or_show, setmovie_or_showFlutter] = useState([]);
    const [FreeMovieShows, setFreeMovieShows] = useState([]);
    const [mainScreenLoader, setmainScreenLoader] = useState();
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const [fadeOut, setFadeOut] = useState(false); // State to control loader animation
    const [loading, setLoading] = useState(true); // Loader is true by default
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const UserId = localStorage.getItem('user_id') || 0;
    const [checkplansuscription, setcheckplansuscription] = useState('');

    if (UserId) {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
            }).catch(err => {
                console.log(err);
            })
    }

    // useEffect(() => {
    // if (location.pathname == '/flutter-home-screen') {
    //     if (Theme_Variable == 'Light') {
    //         document.body.classList.add('no-black-background');
    //         document.body.classList.remove('black-background');
    //     } else if (Theme_Variable == 'Dark') {
    //         document.body.classList.add('black-background');
    //         document.body.classList.remove('no-black-background');
    //     }
    // } else {
    //     document.body.classList.remove('no-black-background');
    //     document.body.classList.remove('black-background');
    // }
    // }, [Theme_Variable]);


    // Slider Flutter

    useEffect(() => {
        setpagesSpinnerFlutter(true);
        const fetchData = async () => {
            setLoading(true); // Ensure loader starts visible

            try {
                // Fetch all data in parallel using Promise.all for better performance
                const [sliderResponse, featuredResponse, tvLiveResponse, movieShowResponse, freeMoviesResponse] = await Promise.all([
                    axios.post(`${API_BASE_URL}api/v1/flutter_home`, {}),
                    axios.post(`${API_BASE_URL}api/v1/flutter_home`, {}),
                    axios.post(`${API_BASE_URL}api/v1/flutter_home`, {}),
                    axios.post(`${API_BASE_URL}api/v1/flutter_home`, {}),
                    axios.post(`${API_BASE_URL}api/v1/flutter_free_movies_serials`, {})
                ]);


                if (childMode === 1 && subUserSelectedAge) {

                    let filteredFeatured = featuredResponse.data.VIDEO_STREAMING_APP.feature.filter(movie => {
                        if (movie.content_rating === '') {
                            return false;
                        }
                        return parseInt(movie.content_rating) <= subUserSelectedAge;
                    });

                    let FreeMoviesShows = freeMoviesResponse.data.VIDEO_STREAMING_APP.free.filter(movieShow => {
                        if (movieShow.content_rating === '') {
                            return false;
                        }
                        return parseInt(movieShow.content_rating) <= subUserSelectedAge;
                    });

                    let moviesShows_genre = movieShowResponse.data.VIDEO_STREAMING_APP.genre_movie_show
                        .filter(moviesShowsGenre => {
                            // Check if `content_rating` exists and meets `subUserSelectedAge` criteria
                            if (moviesShowsGenre.content_rating === '' || parseInt(moviesShowsGenre.content_rating) > subUserSelectedAge) {
                                return false;
                            }
                            // Get genreKey (assumes the second key is the genre name)
                            const genreKey = Object.keys(moviesShowsGenre)[1];
                            const items = moviesShowsGenre[genreKey];

                            // Ensure `items` array exists and filter it based on content rating
                            if (Array.isArray(items) && items.length > 0) {
                                const filteredItems = items.filter(item =>
                                    item.content_rating && parseInt(item.content_rating) <= subUserSelectedAge
                                );
                                // Only keep `moviesShowsGenre` if it has filtered items
                                moviesShowsGenre[genreKey] = filteredItems; // Update items to only contain filtered items
                                return filteredItems.length > 0; // Return true if there are items after filtering
                            }

                            return false; // Skip if `items` is empty or doesn’t exist
                        });


                    setmovie_or_showFlutter(moviesShows_genre);
                    setFreeMovieShows(FreeMoviesShows);
                    setfeatureFlutter(filteredFeatured);

                } else {
                    setmovie_or_showFlutter(movieShowResponse.data.VIDEO_STREAMING_APP.genre_movie_show);
                    setFreeMovieShows(freeMoviesResponse.data?.VIDEO_STREAMING_APP?.free || []);
                    setfeatureFlutter(featuredResponse.data.VIDEO_STREAMING_APP.feature.slice(0, 8));
                }

                // Set the data as needed (simplified)
                setSliderFlutter(sliderResponse.data.VIDEO_STREAMING_APP.slider.slice(0, 8));
                setlivetvFlutter(tvLiveResponse.data.VIDEO_STREAMING_APP.livetv.slice(0, 8));
                // setfeatureFlutter(featuredResponse.data.VIDEO_STREAMING_APP.feature.slice(0, 8));
                // setmovie_or_showFlutter(movieShowResponse.data.VIDEO_STREAMING_APP.genre_movie_show);
                // setFreeMovieShows(freeMoviesResponse.data?.VIDEO_STREAMING_APP?.free || []);

            } catch (err) {
                console.error(err);
            } finally {
                setpagesSpinnerFlutter(false);
                // setFadeOut(true);  // Trigger fade out animation
                setTimeout(() => setLoading(false), 1000); // Wait for animation before hiding loader
            }
        };

        fetchData();
    }, []);


    const handleFlutterMovieShows = (item) => {
        if (item.movie_id) {
            navigate(`/flutter-movie-detail-screen/${item.movie_id}`); // Adjust this path to your movie page route
        } else if (item.show_id) {
            navigate(`/flutter-show-detail-screen/${item.show_id}`); // Adjust this path to your show page route
        }
    }

    const handleFlutterFreeMoviesShows = (Free_Movie_Shows) => {
        if (Free_Movie_Shows.movie_id) {
            navigate(`/flutter-movie-detail-screen/${Free_Movie_Shows.movie_id}`);
        } else if (Free_Movie_Shows.show_id) {
            navigate(`/flutter-show-detail-screen/${Free_Movie_Shows.show_id}`);
        }
    }
    const FeaturedCLick = (feature_slide) => {
        navigate(`/flutter-show-detail-screen/${feature_slide.show_id}`)
    }
    const LiveTvClickFlutter = (live_tv) => {
        if(UserId){
            if (checkplansuscription == 'My Subscription' || live_tv.tv_access == 'Free') {
                navigate(`/flutter-livetv-player/${live_tv.tv_id}`);
            } else if (checkplansuscription === 'Renew subscription plan' || checkplansuscription === 'Please select subscription plan' || live_tv.tv_access === 'Paid') {
                navigate('/flutter-subscription');
            }
        }else{
            navigate('/flutter-login');
        }
    };

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let User_id = localStorage.getItem('user_id');

    return (

        <>
            {/* Nav */}
            <FlutterHomeScreenNav />
            {/* Nav */}
            {/* Flutter Home Slider */}
            <Swiper
                pagination={{ clickable: true }}
                navigation
                modules={[Pagination, Navigation]}
                className="flutterHomeSlider"
            >
                {
                    sliderFlutter.map((slides, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="home_slider"
                                style={{
                                    backgroundImage: `url(${slides.show_poster})`,
                                }}
                            >
                                <div className="flutter_slider_info">
                                    <div className="movie_show_logo">
                                        <img src={slides.show_logo} alt="" />
                                    </div>
                                    <div className="flutter_slider_title">
                                        <p>{slides.show_title}</p>
                                    </div>
                                    <div className="flutter_slider_btn">
                                        <Link to={`/flutter-show-detail-screen/${slides.show_id}`}>
                                            More Information
                                            <img src={play_icon} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            {/* Flutter Home Slider */}
            {
                feature.length > 0 &&
                <section className={`featured_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    <div className="container-fluid">
                        <div className="row justify-content-around">
                            <div className="col-6 col-md-6">
                                <div className="section_movie_show_title view_all_title">
                                    <Link to="/flutter-featured-all-movies-serials">
                                        <h3 className={`text-left ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}><i class={`fa-solid fa-angle-left mr-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i> View All</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                <div className="section_movie_show_title define_title">
                                    <h3 className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Featured</h3>
                                </div>
                            </div>
                        </div>
                        {/* Swiper Of Featured Shows */}
                        {/* Check if loading (pages_spinner) is true, show spinner, else show Swiper */}
                        {pages_spinner ? (
                            <div className="flutter_section_spinner">
                                <div className="flutter_spinned_sections"></div>
                            </div>
                        ) : (
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={8}
                                className="featured_slider"
                            >
                                {feature.map((feature_slide, index) => (
                                    <SwiperSlide
                                        key={index}
                                        className="flutter_featured_slider"
                                        style={{
                                            backgroundImage: `url(${feature_slide.show_poster || feature_slide.movie_poster})`,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => FeaturedCLick(feature_slide)}
                                    >
                                        {/* {(feature_slide.show_cinema == 1 || feature_slide.movie_cinema == 1) && (
                                            <div className="online_screen_logo">
                                                Online Screening
                                                <img src={screen} alt="Online Screening Logo" />
                                            </div>
                                        )} */}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </section>
            }
            {/* Live Broad cast */}
            <section className={`live_broad_cast_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row justify-content-around">
                        <div className="col-6 col-md-6">
                            <div className="section_movie_show_title view_all_title">
                                <Link to="/flutter-livetv-all">
                                    <h3 className={`text-left ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}><i class={`fa-solid fa-angle-left mr-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i> View All</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="section_movie_show_title define_title">
                                <h3 className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Live broadcast</h3>
                            </div>
                        </div>
                    </div>
                    {/* Swiper Of Featured Shows */}
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={8}
                        className="broadcast_slider"
                    >
                        {/* Render spinner first, then Swiper once data is loaded */}
                        {pages_spinner ? (
                            <div className="flutter_section_spinner">
                                <div className="flutter_spinned_sections"></div>
                            </div>
                        ) : (
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={8}
                                className="broadcast_slider"
                            >
                                {livetv.map((live_tv, index) => (
                                    <SwiperSlide
                                        className="swiper_slider_broad_cast"
                                        key={index}
                                        onClick={() => LiveTvClickFlutter(live_tv)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div
                                            className="flutter_broadcast_slider"
                                            style={{
                                                backgroundImage: `url(${live_tv.tv_logo})`,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <div className="live_broad_cast_btn">
                                                Live Broadcast
                                            </div>
                                        </div>
                                        <div className="channel_info_flutter">
                                            <div className="channel_image_flutter">
                                                <img src={live_tv.tv_logo} alt={live_tv.tv_title} />
                                            </div>
                                            <div className={`channel_title_flutter ${Theme_Variable == 'Dark' ? 'paratext_dark_mode' : ''}`}>
                                                <p>{live_tv.tv_title}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}

                    </Swiper>

                </div>
            </section>
            {/* Live Broad cast */}
            {/* watch For Free Movies */}
            {
                FreeMovieShows.length > 0 &&
                <section className={`watch_free_movie_section_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    <div className="container-fluid">
                        <div className="row justify-content-around">
                            <div className="col-6 col-md-6">
                                <div className="section_movie_show_title view_all_title">
                                    <Link
                                        to="/flutter-free-movies-serials">
                                        <h3 className={`text-left ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}><i class={`fa-solid fa-angle-left mr-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i> View All</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                <div className="section_movie_show_title define_title">
                                    <h3 className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Watch for Free</h3>
                                </div>
                            </div>
                        </div>
                        {/* Swiper Of Featured Shows */}
                        {/* Check if loading (pages_spinner) is true, show spinner, else show Swiper */}
                        {pages_spinner ? (
                            <div className="flutter_section_spinner">
                                <div className="flutter_spinned_sections"></div>
                            </div>
                        ) : (
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={6}
                                className="slider_movies_flutter"
                            >
                                {FreeMovieShows.map((Free_Movie_Shows, index) => (
                                    <SwiperSlide
                                        className="free_movies_slider_flutter"
                                        key={index}
                                        onClick={() => handleFlutterFreeMoviesShows(Free_Movie_Shows)}
                                    >
                                        <div className="flutter_movie_show_img serial_image">
                                            {Free_Movie_Shows.show_id && (
                                                <>
                                                    <div
                                                        className="serial__over_flutter"
                                                        style={{
                                                            backgroundImage: `url(${Free_Movie_Shows.show_poster})`,
                                                        }}
                                                    ></div>
                                                    <div
                                                        className="serial__over_2_flutter"
                                                        style={{
                                                            backgroundImage: `url(${Free_Movie_Shows.show_poster})`,
                                                        }}
                                                    ></div>
                                                </>
                                            )}

                                            {/* Main image for fallback */}
                                            <img
                                                src={
                                                    Free_Movie_Shows.movie_poster ||
                                                    Free_Movie_Shows.show_poster
                                                }
                                                alt={
                                                    Free_Movie_Shows.movie_title ||
                                                    Free_Movie_Shows.show_title
                                                }
                                                loading="lazy"
                                            />
                                            <div className="free_movie_logo">
                                                Free <img src={reel} alt="Reel logo" />
                                            </div>
                                        </div>
                                        <div className={`movie_show_title ${Theme_Variable == 'Dark' ? 'paratext_dark_mode' : ''}`}>
                                            <p>
                                                {Free_Movie_Shows.show_title ||
                                                    Free_Movie_Shows.movie_title}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </section>
            }
            <div className="genres__movies__flutter">
                {/* Movies And Shows */}
                {movie_or_show.map((genreObj, index) => {
                    const genreKey = Object.keys(genreObj)[1]; // Assuming the second key is the genre name
                    const items = genreObj[genreKey].slice(0, 8); // Get the first 8 movies or shows for that genre
                    // Check if there are any items in the genre
                    if (items.length === 0) {
                        return null; // Skip rendering this genre section if there are no items
                    }
                    // console.log("Genre : " + genreObj.genre_id);
                    return (
                        <section className={`watch_free_movie_section_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`} key={index}>
                            <div className="container-fluid">
                                <div className="row justify-content-around">
                                    <div className="col-6 col-md-6">
                                        <div className="section_movie_show_title view_all_title">
                                            <Link
                                                to={`/flutter-genre-all-movie-serials/${genreObj.genre_id}`}>
                                                <h3 className={`text-left ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}>
                                                    <i className={`fa-solid fa-angle-left mr-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i> View All
                                                </h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className="section_movie_show_title define_title">
                                            <h3 className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{genreKey}</h3> {/* Display the genre title */}
                                        </div>
                                    </div>
                                </div>
                                {/* Swiper Of Featured Shows */}
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={6}
                                    className="slider_movies_flutter"
                                >
                                    {/* Display the spinner when the pages_spinner is true */}
                                    {pages_spinner ? (
                                        <div className="flutter_section_spinner">
                                            <div className="flutter_spinned_sections"></div>
                                        </div>
                                    ) : (
                                        items.map(item => (
                                            <SwiperSlide
                                                className="free_movies_slider_flutter"
                                                key={item.movie_id || item.show_id}
                                                onClick={() => {
                                                    handleFlutterMovieShows(item);
                                                }}
                                            >
                                                <div className="flutter_movie_show_img serial_image">
                                                    {item.show_id && (
                                                        <>
                                                            <div
                                                                className="serial__over_flutter"
                                                                style={{ backgroundImage: `url(${item.show_poster})` }}
                                                            ></div>
                                                            <div
                                                                className="serial__over_2_flutter"
                                                                style={{ backgroundImage: `url(${item.show_poster})` }}
                                                            ></div>
                                                        </>
                                                    )}

                                                    {/* Main image for fallback */}
                                                    <img
                                                        src={item.movie_poster || item.show_poster}
                                                        alt={item.movie_title || item.show_title}
                                                        loading="lazy"
                                                    />
                                                </div>
                                                <div className={`movie_show_title  ${Theme_Variable == 'Dark' ? 'paratext_dark_mode' : ''}`}>
                                                    <p>{item.movie_title || item.show_title}</p>
                                                    <span className={`${Theme_Variable == 'Dark' ? 'span_home_page_dark' : ''}`}>{item.release_date}</span>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>

                            </div>

                            {/* Sections Loader */}
                            {/* <div className="flutter_section_spinner">
                                <div class="flutter_spinned_sections"></div>
                            </div> */}

                        </section>
                    );
                })}
            </div>

            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}
            {/* <FlutterBottomBar /> */}
        </>
    );
};

export default MainScreen;
