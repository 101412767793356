import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import axios from "axios";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import profile_2 from "../../Images/flutter/profile_2.png";
import change from "../../Images/flutter/chnage.png";
import payment from "../../Images/flutter/NewFlix Images/Icons/payments.png";
// White Theme Images Files 
import payment_icon_white from '../../Images/flutter/White_icons/payments.png';
import about_icon_white from '../../Images/flutter/White_icons/about.png';
import change_viewer_icon_white from '../../Images/flutter/White_icons/change_viewer.png';
import phone_icon_white from '../../Images/flutter/White_icons/phone_white.png';
import moon_icon_white from '../../Images/flutter/White_icons/white__moon.png';
import profile_icon_white from '../../Images/flutter/White_icons/profile_white.png';
import white_arr from '../../Images/flutter/White_icons/white_arr.png'
import subscription_white from '../../Images/flutter/White_icons/subscription_white.png';
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterProfilePage = () => {

    const location = useLocation();
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const [isToggled, setIsToggled] = useState(localStorage.getItem('theme') == 'Dark');
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const [profileRecord, SetProfileRecord] = useState([]);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    let [userplan, setUserplan] = useState('');
    console.log(Theme_Variable);

    // useEffect(() => {
    //     if (location.pathname == '/flutter-profile-page' && Theme_Variable == 'Light') {
    //         document.body.classList.add('no-black-background');
    //     } else if (location.pathname == '/flutter-profile-page' && Theme_Variable == 'Dark') {
    //         document.body.classList.add('black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }
    // }, [location.pathname]);

    useEffect(() => {
        // Check for existing theme in localStorage on initial load
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            if (savedTheme == 'Dark') {
                document.body.classList.add('dark-theme');
            } else {
                document.body.classList.remove('dark-theme');
            }
        }
        // Apply the theme based on isToggled
        if (isToggled) {
            document.body.classList.add('dark-theme');
            localStorage.setItem('theme', 'Dark');
        } else {
            document.body.classList.remove('dark-theme');
            localStorage.setItem('theme', 'Light');
        }
    }, [isToggled]);
    

    const handleToggle = () => {
        setIsToggled(prevState => !prevState);
        setTimeout(() => {
            window.location.reload();
        }, 1000)
    };

    // Profile API
    useEffect(() => {
        setpagesSpinnerFlutter(true);
        // Define API calls
        const profileRequest = axios.post(`${API_BASE_URL}api/v1/profile`, {
            user_id: UserId
        });
        const userPlanRequest = axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        });
        // Run both requests concurrently with Promise.all
        Promise.all([profileRequest, userPlanRequest])
            .then(([profileResponse, userPlanResponse]) => {
                // Handle profile response
                SetProfileRecord(profileResponse.data.VIDEO_STREAMING_APP[0]);
                console.log("Profile response:", profileResponse);
                // Handle user plan response
                const checkPlan = userPlanResponse.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(checkPlan);
                console.log("User plan response:", userPlanResponse);
            })
            .catch(err => {
                console.log("Error fetching data:", err);
            })
            .finally(() => {
                setpagesSpinnerFlutter(false); // Hide spinner after all API calls finish
            });
    }, []);


    return (

        <>
            <FlutterBackHeader />
            <section className="flutter__profile_page">
                {/* <div className="container-fluid"> */}
                {/* <div className="col-12"> */}
                <div className="flutter__profile__subscription__main">
                    <div className="flutter__profile__subscription-image">
                        {
                            Theme_Variable == 'Light' ? (
                                <img src={subscribe} alt="" />) :
                                (<img src={subscription_white} alt="" />)
                        }
                    </div>
                    <div className="message_subscription">

                        <p className={`plan_msg ${(checkplansuscription == 'My Subscription') ? 'plan_subcribed_color' : ''} `}>
                            {checkplansuscription == 'Renew subscription plan' && 'Renew subscription plan'}
                            {checkplansuscription == 'Please select subscription plan' && 'You do not have a subscription'}
                            {(checkplansuscription == 'My Subscription') && userplan.current_plan + " subscribed"}
                            {/* You do not have a subscription */}
                        </p>
                        {/* <p>You don’t have a subscription</p> */}
                    </div>
                    {
                        checkplansuscription != 'My Subscription' && (
                            <div className="subscription__btn__profile">
                                <Link to="/flutter-subscription">
                                    Buy Subscription
                                </Link>
                            </div>)
                    }
                </div>
                {/*  */}
                <div className={`links__before_login_flutter links_on_profile_flutter ${Theme_Variable == 'Dark' ? 'links_on_profile_flutter_white' : ''}`}>
                    <ul className="list-unstyled p-0 m-0">
                        <li>
                            <Link to="/flutter-profile-edit-page">
                                <span>
                                    <div className="icon__main_login user_name_icon_flutter">
                                        {
                                            Theme_Variable == 'Dark' ? (
                                                <img src={profile_2} alt="" />) :
                                                (<img src={profile_icon_white} alt="" />)
                                        }
                                    </div>
                                    <div className={`profile__detail_div ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                        {
                                            (profileRecord.name != '' ? profileRecord.name : 'NA/NA')
                                        }
                                        <p>{profileRecord.phone != '' ? profileRecord.phone : 'NA/NA'}</p>
                                    </div>
                                </span>
                                <div className="left__icon__login__flutter">
                                    {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={() => window.location.href = "/flutter-multi-profiles"}>
                                <span>
                                    <div className="icon__main_login change_viewer ">
                                        {
                                            Theme_Variable == 'Dark' ? (
                                                <img src={change_viewer_icon_white } alt="" />) :
                                                (<img src={change} alt="" />)
                                        }
                                    </div>
                                    <span style={{ display: 'flex', direction: 'rtl' }} className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                        Change Viewer<span>
                                            {localStorage.getItem('sub_account_name') &&
                                                <span>{localStorage.getItem('sub_account_name')}</span>
                                            }
                                        </span>
                                    </span>
                                </span>
                                <div className="left__icon__login__flutter">
                                    {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-payments-history">
                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                    <div className="icon__main_login payment_icons">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={payment} alt="" />) :
                                                (<img src={payment_icon_white} alt="" />)
                                        }
                                    </div>
                                    Payments
                                </span>
                                <div className="left__icon__login__flutter">
                                {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleToggle}>
                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                    <div className="icon__main_login dark__moon_i">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={dark_moon} alt="" />) :
                                                (<img src={moon_icon_white} alt="" />)
                                        }
                                    </div>
                                    {isToggled ? 'Light Theme' : 'Dark Theme'}
                                </span>
                                <div className={`toggle_dark_mode ${isToggled ? 'active active_dark_mode_color' : ''}`}></div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-about-us">
                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                    <div className="icon__main_login dark_about_icon">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={dark_about} alt="" />) :
                                                (<img src={about_icon_white} alt="" />)
                                        }
                                    </div>
                                    About
                                </span>
                                <div className="left__icon__login__flutter">
                                {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-contact-us">
                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                    <div className="icon__main_login dark__contact__flutter">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={dark_contact} alt="" />) :
                                                (<img src={phone_icon_white} alt="" />)
                                        }
                                    </div>
                                    Contact us
                                </span>
                                <div className="left__icon__login__flutter">
                                {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>

            </section>

            {
                pages_spinner && (
                    <div className="flutter_detail_page_spinner">
                        <div className="flutter_section_spinner">
                            <div class="flutter_spinned_sections"></div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default FlutterProfilePage;
