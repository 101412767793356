import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation, Navigate } from "react-router-dom";
import { Theme_Variable } from '../../config';
import { API_BASE_URL } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
// import '../../Components/css/style.css';

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import photoSlider from '../../Images/photo.png';
import actor_logo from '../../Images/actor.png';
import play_icon from '../../Images/flutter/play.png';
import logo_channel from '../../Images/flutter/logo_ch.png';
import free_movie from '../../Images/flutter/free.png';
import detail_head from '../../Images/flutter/detail_pg.png';
import detail from '../../Images/flutter/detail.png';
import play2 from '../../Images/flutter/play2.png';
import comment from '../../Images/flutter/img.png';
import like from '../../Images/flutter/like.png';
import dis_like from '../../Images/flutter/dislike.png';
import profile from '../../Images/flutter/profile.png';
import forward from '../../Images/flutter/forward.png';
import imdb from '../../Images/flutter/imdb.png';
import play_trailer from '../../Images/flutter/play_trailer.png';
import newflix_logo from '../../Images/newflix-logo.png';
import nav2 from '../../Images/flutter/nav2.png';
import nav1 from '../../Images/flutter/nav1.png';
import FlutterNavdetail from "./flutter_component/FlutterNavdetail";

import download_black from '../../Images/flutter/nav/cloud-icon.png'
import watchlist from '../../Images/flutter/nav/watchlist.png'
import shareIcon from '../../Images/flutter/nav/share-icon.png'


import white1 from '../../Images/flutter/nav/white1.png'
import white2 from '../../Images/flutter/nav/white2.png'
import white3 from '../../Images/flutter/nav/white3.png'

import arrwhite from '../../Images/flutter/nav/arr_white.png'
import arrblack from '../../Images/flutter/nav/arr_black.png'


const FlutterMovieDetailPage = () => {

    const location = useLocation();
    const [movieDetail, setMovieDetail] = useState({});
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0;
    const { id } = useParams();
    const [allcomments, setallcomments] = useState([]);
    const [suggestionMovies, setMovieSuggestion] = useState([]);
    const navigate = useNavigate();
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    let [userplan, setUserplan] = useState('');
    let User_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';


    useEffect(() => {
        if (location.pathname == `/flutter-movie-detail-screen/${id}` && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == `/flutter-movie-detail-screen/${id}` && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log(err);
            })
    }, []);
    
    const [checkUserCinemaHas, SetcheckUserCinemaHas] = useState(0);
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/shows_user_cinema_list`, { user_id: UserId || '' })
            .then(response => {
                let movieExists = false; // Initialize as false

                response.data.VIDEO_STREAMING_APP.movies.forEach(movie => {
                    if (movie.movie_id == movieDetail.movie_id) {
                        SetcheckUserCinemaHas(1); // Movie exists, set to 1
                    }
                });
            })
            .catch(err => {
                console.log("something went wrong:" + err);
            });
    }, [UserId, movieDetail.movie_id]);

    // useEffect(() => {
    //     setpagesSpinnerFlutter(true)
    //     axios.post(`${API_BASE_URL}api/v1/movies_details`, {
    //         'movie_id': id
    //     })
    //         .then(response => {
    //             // setLoading(false);
    //             const movieDetails = response.data.VIDEO_STREAMING_APP;
    //             setMovieDetail(movieDetails);
    //             // console.log(movieDetails)
    //         })
    //         .catch(error => {
    //             // setLoading(false);
    //             console.log('There was an error making the request:', error);
    //         }).finally(()=>{
    //             setpagesSpinnerFlutter(false)
    //         });

    //     axios.post(`${API_BASE_URL}api/v1/movies`, {
    //         filter: 'new'
    //         // filter: 'rand'
    //     })
    //         .then(response => {
    //             let random_movies = response.data.VIDEO_STREAMING_APP.movies.slice(0, 8);
    //             setMovieSuggestion(random_movies); // Use setSuggestionMovies to update the state
    //         })
    //         .catch(err => {
    //             console.log('There was an error making the request:', err);
    //         });
    //     // Comments Work 

    //     const listComments = () => {
    //         axios.post(`${API_BASE_URL}api/v1/list_comment`, {
    //             commentable_id: id,
    //             commentable_type: 'Movies',
    //             user_id: UserId,
    //         })
    //             .then(response => {
    //                 let all_comments = response.data.VIDEO_STREAMING_APP;
    //                 setallcomments(all_comments);
    //             })
    //             .catch(err => {
    //                 console.log('There was an error making the request:', err);
    //             });
    //     };
    //     listComments();

    // }, []);

    useEffect(() => {
        setpagesSpinnerFlutter(true);  // Show the spinner initially

        // Fetch movie details, suggestions, and comments concurrently
        Promise.all([
            axios.post(`${API_BASE_URL}api/v1/movies_details`, { 'movie_id': id, user_id: UserId }),
            axios.post(`${API_BASE_URL}api/v1/movies`, { filter: 'new' }),
            axios.post(`${API_BASE_URL}api/v1/list_comment`, { commentable_id: id, commentable_type: 'Movies', user_id: UserId })
        ])
            .then(([movieDetailRes, suggestionMoviesRes, commentsRes]) => {
                setMovieDetail(movieDetailRes.data.VIDEO_STREAMING_APP);
                setMovieSuggestion(suggestionMoviesRes.data.VIDEO_STREAMING_APP.movies.slice(0, 8));
                setallcomments(commentsRes.data.VIDEO_STREAMING_APP);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setpagesSpinnerFlutter(false);  // Hide the spinner after all requests are completed
            });
    }, [id, UserId]);

    const [commentBody, setCommentBody] = useState(''); // Controlled input state for the comment
    const [CommentMessage, setCommentMessage] = useState('');
    const [allComments, setAllComments] = useState([]); // State to store all comments

    const listComments = () => {
        axios.post(`${API_BASE_URL}api/v1/list_comment`, {
            commentable_id: id,
            commentable_type: 'Movies',
            user_id: UserId,
        })
            .then(response => {
                let all_comments = response.data.VIDEO_STREAMING_APP;
                setAllComments(all_comments);
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
    };

    useEffect(() => {
        listComments(); // Load comments when the component is mounted
    }, [id, UserId]);

    // Open login or add comment
    const openLogin = (e) => {
        e.preventDefault();
        if (UserId) {
            saveCommentToAPI();
        } else {
            navigate('/flutter-login');
        }
    };

    // Function to save comment to the API
    const saveCommentToAPI = () => {
        if (commentBody.trim() === '') {
            setCommentMessage('Comment cannot be empty.');
            return;
        }

        axios.post(`${API_BASE_URL}api/v1/add_comment`, {
            content: commentBody,
            commentable_id: id,
            commentable_type: 'Movies',
            user_id: UserId,
        })
            .then(response => {
                setCommentMessage(response.data.VIDEO_STREAMING_APP[0].msg);
                const newComment = {
                    content: commentBody, // Adding the new comment's content
                    user: { name: 'You' }, // You can replace this with actual user info if available
                    created_at: new Date().toISOString(), // Add the timestamp for display
                    // Add other fields if necessary
                };

                // Add the new comment to the top of the comments list without refreshing
                setAllComments([newComment, ...allcomments]);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                // Clear the comment input
                setCommentBody('');
            })
            .catch(error => {
                console.error('Error saving comment:', error);
                setCommentMessage('Error saving comment.');
            });
    };
    // Like Comment Work

    const [likesData, setLikesData] = useState({});

    // Dislike Comment
    const dislikeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/unlike_comment`, {
            comment_id: comment_id,
            user_id: UserId || '', // Ensure user ID is passed correctly
        })
            .then(response => {
                // After disliking, fetch the updated counts
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment disliked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error disliking comment:', error);
            });
    };

    // Like Comment (assuming you have a similar function for likes)
    const likeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/like_comment`, {
            comment_id: comment_id,
            user_id: UserId || '',
        })
            .then(response => {
                // After liking, fetch the updated counts
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment liked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error liking comment:', error);
            });
    };

    // Fetch Like and Dislike Count for a specific comment
    const LikeDislikeCount = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/list_comment_like_unlike`, {
            commentable_id: comment_id,
            commentable_type: 'Movies',
        })
            .then(response => {
                // Extract like and dislike counts from the response
                const data = response.data.VIDEO_STREAMING_APP;
                const like_count = data[0]?.total_likes || 0;
                const dislike_count = data[1]?.total_unlikes || 0;

                // Update the state with the new like/dislike count for the comment
                setLikesData(prevData => ({
                    ...prevData,
                    [comment_id]: { like_count, dislike_count }
                }));
            })
            .catch(error => {
                console.error('Error fetching like/dislike count:', error);
            });
    };

    // Ensure Like and Dislike count is fetched for all comments when they are loaded
    useEffect(() => {
        allComments.forEach(comment => {
            LikeDislikeCount(comment.id);
        });
    }, [allComments]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Options to format the date as "MonthName - Day - Year"
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options).replace(',', ' -');
    };
    const PlayMovieShowTrailer = (movieDetail) => {
        navigate(`/flutter-movie-trailer-page/${movieDetail.movie_id}`);
    }


    const handlwatchnewflix = () => {
        if (!UserId) {
            window.location.href = "/flutter-login";
        } else if (movieDetail.movie_access == 'Free' || checkplansuscription === 'My Subscription') {
            window.location.href = `/flutter-movie-player/${id}`;
            return null;
        } else if (['Renew subscription plan', 'Please select subscription plan'].includes(checkplansuscription)) {
            window.location.href = '/flutter-subscription';
            return null;
        }
    };
    const suggested_movies_click = (suggestions) => {
        navigate(`/flutter-movie-detail-screen/${suggestions.movie_id}`)
    }

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const profileBtnFlutter = () => {
        if (User_id) {
            window.location.href = `/flutter-dashboard/?user_id=${User_id}`;
        } else {
            window.location.href = `/flutter-login`;
        }
    }

    return (

        <>
        
            <FlutterNavdetail user_id={UserId} detail_id={id} type="Movies" />

            <section className={`flutter_detail_page_top`} style={{ backgroundImage: `url(${movieDetail.movie_image})` }}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-12">
                            <div className="detail_page_main">
                                <div className="detail_banner_content">
                                    <h2>{movieDetail.movie_title}</h2>
                                    <p>Over {movieDetail.content_rating != null ? movieDetail.content_rating : '18'} years old -  {movieDetail.movie_duration} - Product of {movieDetail.country_name} - Year {movieDetail.release_date}- HD </p>
                                </div>
                                <div className="detail_banner_img">
                                    <div className="detail_page_image">
                                        <img src={movieDetail.movie_thumb} alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rating_movie_flutter">
                    <div className="rating_count">
                        <span>{movieDetail.imdb_rating}</span>
                    </div>
                    <div className="imbd_logo_flutter">
                        <img src={imdb} alt="" />
                    </div>
                </div>
            </section>
            {/* Flutter Detail movie or show */}
            <section className={`detail_define_movie_show ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="subscription_btn_flutter">
                                <Link to="#" onClick={handlwatchnewflix}>
                                    {/* Buy Subscription */}
                                    {
                                        localStorage.getItem('user_id') ? ( // Check if the user is logged in
                                            (checkplansuscription == 'My Subscription' || movieDetail.movie_access == 'Free' || checkUserCinemaHas == 1) ? (
                                                'Watch Now'
                                            ) : (
                                                (checkplansuscription == 'Renew subscription plan' && movieDetail.movie_access == 'Paid') ? (
                                                    'Buy subscription and watch'
                                                ) : (
                                                    (checkplansuscription == 'Please select subscription plan' && movieDetail.movie_access == 'Paid') ? (
                                                        'Buy subscription and watch'
                                                    ) : (
                                                        'Watch' // If logged in but not subscribed
                                                    )
                                                )
                                            )
                                        ) : (
                                            'Login and watch' // If not logged in
                                        )
                                    }
                                    <img src={play2} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`intro_detail ${Theme_Variable == 'Dark' ? 'paratext_dark_mode' : ''}`}>
                                <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Story of the Film</h2>
                                <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} dangerouslySetInnerHTML={{ __html: movieDetail.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Flutter Detail movie or show */}
            <section className={`similar_recommendation_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="recomment_movie_title">
                        <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Similar Recommendations</h2>
                    </div>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={6}
                        className="similar_movies_main"
                    >
                        {
                            suggestionMovies.map((suggestions, index) => (
                                <SwiperSlide className="similar_movies_recommend_flutter"
                                    key={index} onClick={() => suggested_movies_click(suggestions)}
                                    style={{ cursor: 'pointer' }}>
                                    <div className="free_movie_img">
                                        <img src={suggestions.movie_poster} alt="" loading="lazy" />
                                    </div>
                                    <div className="movie_show_title title_movie_show">
                                        <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                            {suggestions.movie_title.split(' ').length > 3
                                                ? suggestions.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                : suggestions.movie_title}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                </div>
            </section>
            {/* Flutter Movie show trailer */}
            <section className="flutter_trailer_movie_show">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="trailer_flutter_title">
                                <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Movie Preview</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="trailer_image_flutter" style={{ backgroundImage: `url(${movieDetail.movie_image})` }}>
                    <div className="play_trailer_icon"
                        onClick={() => PlayMovieShowTrailer(movieDetail)}
                    >
                        <img src={play_trailer} alt="" />
                    </div>
                </div>
            </section>
            {/* Flutter Movie show trailer */}
            {/* Production Team Cast And Crew */}
            <section className="cast_and_crew_flutter">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title_crew">
                                <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Production Team</h2>
                            </div>
                            <div className="actors_flutter_movie_show">
                                <div className="title_actor_flutter">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Actor</h2>
                                    <ul className="actor_names_flutter list-unstyled d-flex">

                                        {
                                            movieDetail.actor_list && movieDetail.actor_list.length > 0 ? (
                                                movieDetail.actor_list.map((actor, index) => (
                                                    <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{actor.ad_name}</li>
                                                ))
                                            ) : (
                                                <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No Actor Found</li>
                                            )
                                        }
                                    </ul>
                                </div>
                                {/* Director */}
                                <div className="title_actor_flutter">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Director</h2>
                                    <ul className="actor_names_flutter list-unstyled d-flex">
                                        {
                                            movieDetail.director_list && movieDetail.director_list.length > 0 ? (
                                                movieDetail.director_list.map((director, index) => (
                                                    <li className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} key={index}>{director.ad_name}</li>
                                                ))
                                            ) : (
                                                <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No director found</li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section comments Flutter */}
            <section className="comments_main_flutter">
                <div className="container-fluid">
                    <div className="comments_heading_flutter">
                        <div className="row">
                            <div className="col-6">
                                <p className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}><i className={`fa-solid fa-angle-left ml-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i>  All Comments</p>
                            </div>
                            <div className="col-6">
                                <p className={`text-right text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Comments</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {allcomments.length > 0 ? (
                                allcomments.map((comment, index) => (
                                    <div className="comments_flutter" key={index}>
                                        <div className="comment_info_main_flutter">
                                            <div className="comment_user_image" style={{
                                                maxWidth: '27px',
                                                minWidth: '27px'
                                            }}>
                                                <img src={profile} alt="User profile" />
                                            </div>
                                            <div className="comment_user_name">
                                                <p className={`text-right all_comments_clr ${Theme_Variable === 'Dark' ? 'white_text_mode' : ''}`}>
                                                    {comment.user ? comment.user.name : ''}
                                                </p>

                                                <span className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{formatDate(comment.created_at)}</span>
                                            </div>
                                        </div>
                                        <div className="comment_body_flutter">
                                            <p className={`text-right  all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{comment.content}</p>
                                        </div>
                                        <div className="actions_comments">
                                            <span className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} onClick={() => dislikeComment(comment.id)}>
                                                <img src={dis_like} alt="Dislike" /> {likesData[comment.id]?.dislike_count || 0}
                                            </span>

                                            <span className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} onClick={() => likeComment(comment.id)}>
                                                <img src={like} alt="Like" /> {likesData[comment.id]?.like_count || 0}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span className={`no-comments text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No Comments here</span>
                            )}
                        </div>
                    </div>
                    {/*  */}
                    <div className="row">
                        <div className="col-12">
                            <div className="input_comment_flutter">
                                <div className="profile_image_input">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="input_comment">
                                    <form
                                        id="add-new-comment--form"
                                        onSubmit={openLogin}
                                        className="form ui-br-4 d-inline-block w100 ps-relative"
                                    >
                                        <input
                                            name="comment_body"
                                            maxLength="800"
                                            id="comment_body"
                                            type="text"
                                            placeholder="Write your comment about the film here…"
                                            value={commentBody}
                                            onChange={(e) => setCommentBody(e.target.value)} // Controlled input
                                        />
                                        {/* Use a button for submit */}
                                        <button type="submit" className="input_icon" style={{ border: 'none', background: 'none', minWidth: '33px' }}>
                                            <img src={forward} alt="Submit" />
                                        </button>
                                    </form>
                                    {CommentMessage && <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{CommentMessage}</p>} {/* Display success/error messages */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}

        </>
    )


}
export default FlutterMovieDetailPage;