import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import search from '../../Images/flutter/search.jpg';
import arr from '../../Images/flutter/arrow_right.png';
import arr_black from '../../Images/flutter/arr_black.png';
import free from '../../Images/flutter/free.png';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import not_found_search from '../../Images/flutter/White_icons/not_found_search.jpg';


const FlutterSearchPage = () => {
    const navigate = useNavigate();
    const [searchOpen, setSearchOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // Add or remove class based on the current route
        if (location.pathname == `/flutter-search-page`) {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == `/flutter-search-page` && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }

    }, [location.pathname]);

    // For search filter
    const [searchResults, setSearchResults] = useState({
        movies: [],
        live_tv: [],
        shows: []
    });


    const searchFunct = (event) => {
        const searchText = event.target.value;
        setLoading(true);
        // if (event.key === 'Enter') {
        //     navigate(`/search/${searchText}`);
        //     setSearchOpen(false);
        // }
        axios.post(`${API_BASE_URL}api/v1/search`, {
            search_text: searchText
        })
            .then(response => {
                setSearchResults({
                    movies: response.data.VIDEO_STREAMING_APP.movies || [],
                    live_tv: response.data.VIDEO_STREAMING_APP.live_tv || [],
                    shows: response.data.VIDEO_STREAMING_APP.shows || []

                });
                setLoading(false);
                // console.log(response.data.VIDEO_STREAMING_APP);
            })
            .catch(err => {
                console.log("Error on search: " + err);
            });

    };

    const playMovieSearch = (movie_id) => {
        window.location.href = `/flutter-movie-detail-screen/${movie_id}`
    }

    return (
        <>
            <section className={`search__flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="search__bar__flutter">
                    <div className="search__bar__main">
                        <input type="text" placeholder="Search" className={`search__bar_flutter_input  ${Theme_Variable == 'Dark' ? 'dark_input_field' : ''}`}
                            onKeyUp={searchFunct} />
                        <div className="search__bar__icon" onClick={() => navigate('/flutter-home-screen')}>
                            {
                                Theme_Variable == 'Dark' ? (
                                    <img src={arr} alt="" />) :
                                    (<img src={arr_black} alt="" />)
                            }
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="seacrh__item_found">
                                {/* Items Founds */}
                                {(searchResults.movies.length > 0 || searchResults.live_tv.length > 0 || searchResults.shows.length > 0) ? (
                                    <>
                                        {searchResults.movies.length > 0 && (
                                            <>
                                                <div className="item__search_know_search">
                                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Movies</h2>
                                                </div>
                                                {searchResults.movies.map((movie, index) => (
                                                    <div key={index} className="item__main__search__flutter" onClick={() => playMovieSearch(movie.movie_id)} style={{ cursor:'pointer'}}>
                                                        <div className="item__image__flutter">
                                                            <img src={movie.movie_poster} alt={movie.movie_title} />
                                                        </div>
                                                        <div className="item__content__flutter">
                                                            <p className={`title__Search ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{movie.movie_title}</p>
                                                            <p className={`item__access_flutter ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{movie.movie_access}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                        {searchResults.live_tv.length > 0 && (
                                            <>
                                                <div className="item__search_know_search">
                                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Live TV</h2>
                                                </div>
                                                {searchResults.live_tv.map((live, index) => (
                                                    <div key={index} className="item__main__search__flutter" onClick={() => window.location.href = `/flutter-livetv-player/${live.tv_id}`}  style={{ cursor:'pointer'}}>
                                                        <div className="item__image__flutter">
                                                            <img src={live.tv_logo} alt={live.tv_title} />
                                                        </div>
                                                        <div className="item__content__flutter">
                                                            <p className={`title__Search  ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{live.tv_title}</p>
                                                            <p className={`item__access_flutter  ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''} `}>{live.tv_access}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                        {searchResults.shows.length > 0 && (
                                            <>
                                                <div className="item__search_know_search">
                                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Shows</h2>
                                                </div>
                                                {searchResults.shows.map((show, index) => (
                                                    <div key={index} className="item__main__search__flutter" onClick={() => window.location.href = `/flutter-show-detail-screen/${show.show_id}`}  style={{ cursor:'pointer'}}>
                                                        <div className="item__image__flutter">
                                                            <img src={show.show_poster} alt={show.show_title} />
                                                        </div>
                                                        <div className="item__content__flutter">
                                                            <p className={`title__Search ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{show.show_title}</p>
                                                            <p className={`item__access_flutter ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{show.show_access}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    // Not Found Message
                                    <div className="search__not_found">
                                        <div className="search__found__img">
                                            {
                                                Theme_Variable == 'Dark' ? (
                                                    <img src={not_found_search} alt="" />) :
                                                    (<img src={search} alt="" />)
                                            }
                                        </div>
                                        <div className="search__msg_flutter">
                                            <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                For searching for your favorite movie or show, enter its name or part of its title.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                loading &&
                <div className="full-screen_loader">
                    <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    )
}

export default FlutterSearchPage;