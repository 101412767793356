import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import axios from "axios";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import delete_device from "../../Images/flutter/delete.png"
import white_device from "../../Images/flutter/white_device.png";
import logout from "../../Images/flutter/logout.png";
import just_icon from "../../Images/flutter/just_icon.png";
import email_contact from "../../Images/flutter/email_contact.png";
import contact_icon from "../../Images/flutter/contact_page.png";
import telegram_page from "../../Images/flutter/telegram_page.png";
import FlutterBackHeader from "./FlutterBackHeader";


const FlutterContactUs = () => {

    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [messagealert, setMessageAlert] = useState('');
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const [watchListAdded, setWatchListAdded] = useState(false);
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        // Check the current route and apply/remove the class accordingly
        if (location.pathname === '/flutter-contact-us') {
            document.body.classList.add('contact-us-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    return (
        <>
        <FlutterBackHeader/>
        <section className="flutter__contact__main">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="contact__flutter_heading">
                            <h2>Contact Us</h2>
                            <p>You can contact Filimo by sending an email to the relevant department.</p>
                        </div>
                        {/* Boxes contact */}
                        <div className="contact__us__box_main">
                            <div className="contact__us_box">
                                <div className="contact__us_icon_image">
                                    <img src={email_contact} alt="" />
                                </div>
                                <div className="contact__box__para">
                                    <p>You can contact Newflix by sending an email to the relevant department.</p>
                                </div>
                                <div className="sub__section__contact">
                                    <p>info@newflix.net</p>
                                    <p>(General inquiries & suggestions)</p>
                                </div>

                                <div className="sub__section__contact">
                                    <p>[email address removed]</p>
                                    <p>(General inquiries & suggestions)</p>
                                </div>

                                <div className="sub__section__contact">
                                    <p>[email address removed]</p>
                                    <p>(General inquiries & suggestions)</p>
                                </div>
                            </div>
                        </div>

                        <div className="contact__us__box_main">
                            <div className="contact__us_box">
                                <div className="contact__us_icon_image">
                                    <img src={contact_icon} alt="" />
                                </div>
                                <div className="sub__section__contact d-block text-center">
                                    <p>(021-74524)</p>
                                </div>
                                <div className="sub__section__contact d-block text-center">
                                    <p style={{color:'#A1A1A1'}}>We are available 24/7 to answer your questions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="contact__us__box_main">
                            <div className="contact__us_box">
                                <div className="contact__us_icon_image">
                                    <img src={telegram_page} alt="" />
                                </div>
                                <div className="sub__section__contact d-block text-center">
                                    <p>@Newflix_support</p>
                                </div>
                                <div className="sub__section__contact d-block text-center">
                                    <p style={{color:'#A1A1A1'}}>Available from 8 AM to 2 AM</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default FlutterContactUs;