import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import detail_head from '../../Images/flutter/detail_pg.png';
import arrow_right from '../../Images/flutter/arrow_right.png';
import { Theme_Variable } from '../../config';
import hd from '../../Images/flutter/hd.png';
import axios from "axios";
import FlutterPlayer from "../../Components/FlutterPlayer";

const FlutterOtherEpisodes = () => {
    
    const location = useLocation();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const seasonId = queryParams.get('season_id');
    const showDetailId = queryParams.get('show_detail_id');
    const { id } = useParams();
    const [showDetail, setShowDetail] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [seasonsFlutter, setSeasonsFlutter] = useState([]);
    const [episodeFlutter, setEpisodeFlutter] = useState([]);
    const [pagesSpinner, setPagesSpinner] = useState(true);
    const [seasonName, setSeasonName] = useState('');
    const [checkplansuscription, setcheckplansuscription] = useState('');
    let [userplan, setUserplan] = useState('');
    const [watchepisode, setEpisodewatch] = useState([]);

    // Toggle overlay
    const toggleActiveClass = () => setIsActive(!isActive);

    // Handle season selection
    const handleSeasonSelect = (newSeasonId) => {
        queryParams.set('season_id', newSeasonId);
        setIsActive(false);
        setPagesSpinner(true);

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        }, { replace: true });

        // Fetch new episodes for the selected season
        axios.post(`${API_BASE_URL}api/v1/episodes`, { season_id: newSeasonId })
            .then(response => {
                const seasons_episodes = response.data.VIDEO_STREAMING_APP;
                setEpisodeFlutter(seasons_episodes);
                setPagesSpinner(false);
            })
            .catch(err => console.error(err));
    };

    useEffect(() => {
        // Add or remove class based on the current route
        if (location.pathname == `/flutter-other-episodes` && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == `/flutter-other-episodes` && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }

    }, [location.pathname]);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        setPagesSpinner(true); // Set spinner to true before fetching data

        // Use Promise.all to fetch show details, seasons, and episodes
        Promise.all([
            axios.post(`${API_BASE_URL}api/v1/show_details`, { show_id: showDetailId }),
            axios.post(`${API_BASE_URL}api/v1/seasons`, { show_id: showDetailId }),
            axios.post(`${API_BASE_URL}api/v1/episodes`, { season_id: seasonId }),
        ])
            .then(([showDetailResponse, seasonsResponse, episodesResponse]) => {
                const showDetails = showDetailResponse.data.VIDEO_STREAMING_APP;
                const seasons_flutter = seasonsResponse.data.VIDEO_STREAMING_APP;
                const seasons_episodes = episodesResponse.data.VIDEO_STREAMING_APP;

                setShowDetail(showDetails);
                setSeasonsFlutter(seasons_flutter);
                setEpisodeFlutter(seasons_episodes);

                // Match season ID from the URL to fetch the correct season name
                const matchedSeason = seasons_flutter.find(season => season.season_id == seasonId);
                if (matchedSeason) {
                    setSeasonName(matchedSeason.season_name);
                }
            })
            .catch(err => console.error(err))
            .finally(() => setPagesSpinner(false)); // Hide spinner after all requests complete
    }, [seasonId, showDetailId]);

    const handleEpisodeClick = (click_id_episode) => {
        // setPagesSpinner(true);
        // navigate(`/flutter-episode-detail/${click_id_episode}?season_id=${seasonId}&show_detail_id=${showDetailId}`);
        setPagesSpinner(true);
        const queryParams = `?season_id=${seasonId}&show_detail_id=${showDetailId}`;
        localStorage.setItem('episodeQueryParams', queryParams); // Save parameters
        navigate(`/flutter-episode-detail/${click_id_episode}${queryParams}`);
    }
    return (
        <>
            {/* Season Header */}
            <section className="flutter_other_episodes" style={{ backgroundImage: `url(${detail_head})` }}>
                <div className="title_head_season">
                    <Link to="#" onClick={() => navigate(-1)} className="m-0">
                        Back
                        <img src={arrow_right} alt="Back Arrow" />
                    </Link>
                </div>
                <div className={`other_seasons_dropdown ${seasonsFlutter.length > 1 ? 'clickable' : ''}`} onClick={seasonsFlutter.length > 1 ? toggleActiveClass : null}>
                    <div className="heading_other_episodes">
                        <p>{seasonName || "Season"}</p>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
            </section>
            {/* Episodes List */}
            <section className="all_episodes_fetch">
                <div className={`episodes_row ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    {
                        episodeFlutter.length > 0 ? (
                            episodeFlutter.map((allEpisodes, index) => (
                                <div className="episodes_main"  onClick={(e) => {
                                    e.preventDefault();
                                    handleEpisodeClick(allEpisodes.episode_id);
                                }}
                                    key={index}>
                                    <img src={allEpisodes.episode_image} alt={allEpisodes.episode_title} />
                                    <div className="hd_icon">
                                        <img src={hd} alt="HD Icon" />
                                    </div>
                                    <div className="episode_title_flutter">
                                        <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{allEpisodes.episode_title.split(' ').slice(0, 2).join(' ')}...</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no_available_msg">
                                <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No Available Episodes in this season</p>
                            </div>
                        )
                    }
                </div>
            </section>
            {/* Overlay for Seasons */}
            <section className={`all_seasons_over_lay ${isActive ? 'active_overlay_flutter' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="seasons_list_over_lay_flutter">
                                <ul>
                                    {
                                        seasonsFlutter.length > 0 ? (
                                            seasonsFlutter.map((season, index) => (
                                                <li key={index}>
                                                    <Link to="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSeasonSelect(season.season_id);
                                                    }}>
                                                        {season.season_name}
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No seasons available</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_over_lay_flutter" onClick={toggleActiveClass}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
            </section>
            {/* Loading Spinner */}
            {pagesSpinner && (
                <div className="flutter_section_spinner">
                    <div className="flutter_spinned_sections"></div>
                </div>
            )}
        </>
    );
}

export default FlutterOtherEpisodes;
