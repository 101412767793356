import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme_Variable } from "../../../config";

const FlutterNavTabs = () => {
  const UserId = localStorage.getItem('user_id') || ''; // Check if user is logged in
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    // Check the current route and apply/remove the class accordingly
    if (location.pathname === "/flutter-swiper-tab") {
      document.body.classList.toggle('no-black-background', Theme_Variable === 'Light');
      document.body.classList.toggle('black-background', Theme_Variable === 'Dark');
    } else {
      document.body.classList.remove('no-black-background', 'black-background');
    }

    setActiveTab(location.pathname); // Update active tab based on current path
  }, [location.pathname]);

  const handleTabClick = (path) => {
    navigate(path); // Navigate to the selected path
    setActiveTab(path); // Update active tab
  };

  return (
    <section className={`tabs_main_section ${Theme_Variable == 'Dark' ? 'scrolled_dark' : ''}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="nav_tabs_main">
              <ul>
                <li onClick={() => handleTabClick('/flutter-offline-movies')}>
                  <div className={`tab-link ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                    Gallery Offline
                    <div className="spacer"></div>
                    {activeTab === '/flutter-offline-movies' && <div className="active_tab"></div>}
                  </div>
                </li>
                <li onClick={() => handleTabClick('/flutter-viewed-page')}>
                  <div className={`tab-link ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                    Viewed
                    <div className="spacer"></div>
                    {activeTab === '/flutter-viewed-page' && <div className="active_tab"></div>}
                  </div>
                </li>
                <li onClick={() => handleTabClick('/flutter-watch-list-page')}>
                  <div className={`tab-link ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                    Watched List
                    <div className="spacer"></div>
                    {activeTab === '/flutter-watch-list-page' && <div className="active_tab"></div>}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlutterNavTabs;
